<template>
  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries.</span>
  </b-col>
</template>

<script>
import {BCol} from "bootstrap-vue";

export default {
  name: "FooterTable",
  components: {BCol},
  props: ['dataMeta'],
}
</script>
