<template>
  <b-link :to="{ name: routeName, params: { id: data }}" class="font-weight-bold">
    #{{ data }}
  </b-link>
</template>

<script>
import {BLink} from "bootstrap-vue";

export default {
  components: {BLink},
  props: ['data', 'routeName'],
}
</script>

<style scoped>

</style>