<template>
  <span class="text-nowrap">{{ formatDate(data) }}</span>
</template>

<script>
import {formatDate} from "@core/utils/filter";

export default {
  methods: {
    formatDate,
  },
  props: ['data'],
}
</script>

<style scoped>

</style>