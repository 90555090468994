<template>
  <span class="text-nowrap">
    <span v-if="!dropdown">
      <feather-icon
          :id="`row-${data.item.id}-remove`"
          icon="TrashIcon"
          size="16"
          @click="removeRecord(data.item.id)"/>
    </span>

    <b-dropdown-item @click="removeRecord(data.item.id)" v-if="dropdown">
      <feather-icon icon="TrashIcon"/>
      <span class="align-middle ml-50">Remove</span>
    </b-dropdown-item>
  </span>
</template>

<script>
import {BDropdownItem, BTooltip} from "bootstrap-vue";
import store from "@/store";

export default {
  name: "RemoveButtonTable",
  components: {BDropdownItem, BTooltip},
  props: {
    reFetchData: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    tooltipNotVisible: {
      type: Boolean,
      default: false,
    },
    removeUrl: {
      type: String,
      default: "removeItem"
    }
  },
  methods: {
    removeRecord(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Record deleted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/' + this.removeUrl, {id: id}).then(response => {
            if (response.data.status === 'success') {
              this.reFetchData()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Record removed.',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.error_message,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    },
  }
}
</script>
